import { GuestPetDto } from '../../autogenerated/autogeneratedTypes'

export const guestPetValidator = (pet: GuestPetDto): boolean => {
	if (pet.type === 'CAT') {
		return true
	}

	if (pet.weight === '0' || pet.weight === undefined) {
		return false
	}

	return true
}
