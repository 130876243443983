import { z } from 'zod'

/**
 * Copy of {@link StayPeriodDto} but with date's
 */
export const StayPeriodDate = z.object({
	checkIn: z.date(),
	checkOut: z.date()
})

export type StayPeriodDate = z.infer<typeof StayPeriodDate>
